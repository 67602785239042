#contact-section {
  background-color: rgba(201, 207, 212, 90%);
  padding: 20px 0px 25px 0px;
}

#contact-section > h1 {
  margin-top: 20px;
}

#contact > #contact-info {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 75%;
}

#contact {
  text-align: center;
}

body {
  scroll-behavior: smooth;
}