/* elevate brand colors: */

/* blue */
/* r80/g146/b195 */
/* rgb(80, 146, 195); */

/* black */
/* r8 / g10 / b7 */
/* rgb(8, 10, 7); */

/* dark gray */
/* rgb(61, 63, 66); */

/* light gray */
/* r201/g207/b212 */
/* rgb(201, 207, 212); */

html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}

body {
  background: #F2F2F3;
  width: 100%;
  scroll-behavior: smooth;
}

.App {
  margin: 0 auto;
}

h1, h3 {
  color: rgb(80, 146, 195);
}

h2, h4 {
  color: rgb(61, 63, 66);
  font-weight: 700;
}

h3 {
  font-size: 24px;
}

p {
  font-size: 16px;
  color: rgb(8, 10, 7);
}

.section-header {
  text-align: center;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  html {
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
  }
}