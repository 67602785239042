body {
  scroll-behavior: smooth;
}

#capabilities-section {
  background-color: rgba(201, 207, 212, 70%);
  padding: 20px 0px 25px 0px;
}

#capabilities-content {
  margin-top: 50px;
}

#capability-statement,
#competencies,
#advantage,
#performance,
#snapshot {
  width: 75%;
  margin: 0 auto;
}
