body {
  overflow-x: hidden;
  /* overflow-y: hidden; */
  scroll-behavior: smooth;
}

header {
  background-color: rgba(80, 146, 195, 30%);;
  background: no-repeat
    url('https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1521914921483-TDVFWOD0B3XO31NZEXHL/ke17ZwdGBToddI8pDm48kE1G8aDDySyXafgMqMi-3Wt7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0k5fwC0WRNFJBIXiBeNI5fIBAe0aRMxRrpn6J5i2IfAjEZwkdJGvTULFlkLSJhK3Nw/GettyImages-116631253.jpg');
  background-size: 100vw 100%;
  box-shadow: inset 0 0 0 1000px rgba(242, 242, 243, 0.3);
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img#header-logo {
  height: 100px;
}

h1#header-text {
  font-size: 90px;
  color: rgb(61, 63, 66);
  padding: 0px 0px 15px 0px;
  margin: 0 auto;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 40%);
}

#header-arrow {
  color: rgba(61, 63, 66, 70%);
  font-weight: 600;
  font-size: 45px;
  margin-top: 400px;
  text-shadow: 1px 1px 1px rgba(61, 63, 66, 40%);
}

#solutions-span {
  color: rgb(80, 146, 195);
  text-shadow: 1px 1px 1px rgba(80, 146, 195, 40%);
}

#change-span {
  /* color: rgb(8, 10, 7); */
}

@media only screen and (min-width: 801px) and (max-width: 1100px) {

  body {
    overflow-x: hidden;
  }

  header {
    background-size: auto 100%;
  }

  .header-animation {
    margin: 0 auto;
  }

  img#header-logo {
    height: 90px;
  }

  h1#header-text {
    font-size: 70px;
  }

  #header-arrow {
    bottom: 0;
    right: 50%;
    margin-top: 200px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {

  body {
    overflow-x: hidden;
  }

  header {
    background-size: auto 100%;
  }

  .header-animation {
    margin: 0 auto;
  }

  img#header-logo {
    height: 80px;
  }

  h1#header-text {
    font-size: 60px;
  }

  #header-arrow {
    bottom: 0;
    right: 50%;
    margin-top: 200px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  header {
    background: no-repeat
      url('https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1521914921483-TDVFWOD0B3XO31NZEXHL/ke17ZwdGBToddI8pDm48kE1G8aDDySyXafgMqMi-3Wt7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0k5fwC0WRNFJBIXiBeNI5fIBAe0aRMxRrpn6J5i2IfAjEZwkdJGvTULFlkLSJhK3Nw/GettyImages-116631253.jpg');
    background-size: auto 100%;
  }

  img#header-logo {
    height: 60px;
  }

  h1#header-text {
    font-size: 48px;
    max-width: 80%;
  }

  #header-arrow {
    bottom: 0;
    right: 50%;
    margin-top: 200px;
    font-size: 30px;
  }
}
