body {
  scroll-behavior: smooth;
}

#bios {
  margin-top: 50px;
}

#bios > div {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 50px;
}

#bios > div:nth-child(even) {
  text-align: right;
}