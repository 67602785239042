footer {
  width: 100%;
  text-align: center;
  padding: 15px 0 15px 0;
  bottom: 0;
  background-color: rgb(61, 63, 66);
  color: rgb(201, 207, 212);
}

footer > p {
  color: rgb(201, 207, 212);
  font-weight: 600;
}

footer > p > a {
  transition-property: color;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  color: rgb(201, 207, 212);
  font-size: 18px;
  font-weight: 600;
}

footer > p > a:hover {
  color: rgba(201, 207, 212, 80%);
}

body {
  scroll-behavior: smooth;
}