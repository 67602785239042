body {
  scroll-behavior: smooth;
}

#home {
  padding: 0px 0px 25px 0px;
  margin-bottom: 50px;
}

#statements {
  max-width: 75%;
  margin: 0 auto;
  margin-top: 50px;
}

#statements > section {
  margin-bottom: 50px;
}